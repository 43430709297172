<template>
  <el-dialog :title="info.id?'编辑':'添加方案'"
    :visible.sync="dialogVisible"
    custom-class="policy-dialog"
    :close-on-click-modal="false"
    width="950px"
    @close="dialogClose"
    :before-close="handleClose">
    <div class="content">
      <div class="basic">
        <div class="form-item"
          v-if="permissions.schemeName">
          <div class="label required">方案名称</div>
          <div class="info">
            <el-input placeholder="请填写方案名称"
              v-model="form.schemeName"
              @input="InputcalculatePremium"></el-input>
          </div>
        </div>
        <div class="form-item"
          v-if="permissions.categoryName">
          <div class="label required">所属部门</div>
          <div class="info">
            <el-select v-model="form.categoryName"
              placeholder="请选择">
              <el-option v-for="item in category_name"
                :key="item.dictKey"
                :label="item.dictValue"
                :value="item.dictKey"></el-option>
            </el-select>
          </div>
        </div>
        <div class="form-item"
          v-if="permissions.productLineName">
          <div class="label">设备分类</div>
          <div class="info">
            <el-select v-model="form.productLineName"
              placeholder="请选择">
              <el-option v-for="item in vehicleType"
                :key="item.dictKey"
                :label="item.dictValue"
                :value="item.dictKey"></el-option>
            </el-select>
          </div>
        </div>
        <div class="form-item"
          v-if="permissions.companyName">
          <div class="label">保险公司</div>
          <div class="info">
            <el-select v-model="form.companyName"
              @change="handleCompanyName"
              placeholder="请选择">
              <el-option v-for="item in insuranceCompany"
                :key="item.dictKey"
                :label="item.dictValue"
                :value="item.dictKey"></el-option>
            </el-select>
          </div>
        </div>
        <div class="form-item"
          v-if="permissions.branches">
          <div class="label">分支机构</div>
          <div class="info">
            <el-input placeholder="请填写分支机构"
              v-model="form.branches"></el-input>
          </div>
        </div>
        <div class="form-item"
          v-if="permissions.scopeOfApplication">
          <div class="label">适用范围</div>
          <div class="info">
            <el-input placeholder="请填写适用范围"
              v-model="form.scopeOfApplication"></el-input>
          </div>
        </div>
        <div class="form-item"
          v-if="permissions.sort">
          <div class="label">排序</div>
          <div class="info">
            <el-input placeholder="请填写排序"
              v-number-input.float
              v-model="form.sort"></el-input>
          </div>
        </div>
        <div class="form-item"
          v-if="permissions.recorder">
          <div class="label required">录单员</div>
          <div class="info">
            <el-select v-model="form.recorder"
              placeholder="请选择"
              value-key="userId"
              size="medium"
              @change="handleSelect">
              <el-option v-for="item in chargeUserList"
                :key="item.userId"
                :label="item.userName"
                :value="item"></el-option>
            </el-select>
          </div>
        </div>
        <div class="form-item">
          <div class="label required">查看用户</div>
          <div class="info">
            <EmployeeSelect v-model="form.readerId"
              @change="handleCheckSelect"
              isMulti />
          </div>
        </div>
      </div>
      <div class="line"></div>
      <div class="form-plan">
        <div class="label">保障计划</div>
        <div class="info">
          <el-table :data="form.policySafeguardPlanList"
            style="width: 100%"
            :border="true">
            <el-table-column type="index"
              label="序号"
              width="50"
              align="center"></el-table-column>
            <el-table-column prop="nameOfInsuranceClause"
              label="险种（条款）名称"
              width="180"
              align="center">
              <template slot-scope="scope">
                <el-select v-if="scope.$index === 0"
                  v-model="scope.row.nameOfInsuranceClause"
                  @change="handleMainSelect($event,scope.row)"
                  placeholder="请选择">
                  <el-option v-for="item in options"
                    :key="item.dictKey"
                    :label="item.dictValue"
                    :value="item.dictValue"></el-option>
                </el-select>
                <el-select v-else
                  v-model="scope.row.nameOfInsuranceClause"
                  @change="handleAttachSelect($event,scope.row)"
                  placeholder="请选择">
                  <el-option v-for="item in attachOptions"
                    :key="item.dictKey"
                    :label="item.dictValue"
                    :value="item.dictValue"
                    :disabled="attachOptionsDdisabled(item)"></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column prop="name"
              label="保额/限额"
              align="center">
              <template slot-scope="scope">
                <el-input placeholder="请填写"
                  v-model="scope.row.insuranceAmount"
                  @input="amountInput(scope.row)"
                  v-number-input.float="2"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="address"
              label="每次赔偿限额"
              align="center">
              <template slot-scope="scope">
                <el-input placeholder="请填写"
                  v-model="scope.row.maximumIndemnityNorm"
                  v-number-input.float="2"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="address"
              label="年费率"
              align="center"
              width="140">
              <template slot-scope="scope">
                <div style="display:flex;align-items: center;">
                  <el-input placeholder="请填写"
                    v-model="scope.row.annualFee"
                    @input="handleInput(scope.row)"
                    v-number-input.float="3"></el-input>
                  <el-dropdown @command="handleCommand"
                    trigger="click">
                    <span class="el-dropdown-link">
                      {{scope.row.rateType}}
                      <i class="el-icon-arrow-down"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item :command="{data:scope.row,value:'%'}">%</el-dropdown-item>
                      <!-- <el-dropdown-item :command="{data:scope.row,value:'￥'}">￥</el-dropdown-item> -->
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="address"
              label="保费"
              align="center">
              <template slot-scope="scope">
                <el-input placeholder="请填写"
                  v-model="scope.row.premium"
                  @input="InputcalculatePremium(scope.row)"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="operate"
              label="操作"
              width="160px"
              align="center">
              <template slot-scope="scope">
                <el-button v-if="scope.row.stipulation"
                  type="text"
                  @click="showClause(scope.row)">查看条款</el-button>
                <el-button v-if="scope.$index !== 0"
                  type="danger"
                  plain
                  @click="delTableItem(scope.$index,scope.row.nameOfInsuranceClause,scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-button @click="tableAdd"
            type="primary"
            class="btn"
            plain>+ 增加附加险</el-button>
        </div>
      </div>
      <div class="form-textarea"
        v-if="permissions.freeExtensionTerms">
        <div class="label">免费扩展条款</div>
        <div class="info">
          <el-input type="textarea"
            :autosize="{ minRows: 4}"
            placeholder="请输入内容"
            v-model="form.freeExtensionTerms"
            show-word-limit
            maxlength="3000"></el-input>
        </div>
      </div>
      <div class="form-textarea"
        v-if="permissions.specialAgreement">
        <div class="label">特别约定</div>
        <div class="info">
          <el-input type="textarea"
            :autosize="{ minRows: 4}"
            placeholder="请输入内容"
            v-model="form.specialAgreement"
            show-word-limit
            maxlength="3000"></el-input>
        </div>
      </div>
      <div class="form-textarea"
        v-if="permissions.specialVersion">
        <div class="label">特别说明</div>
        <div class="info">
          <el-input type="textarea"
            :autosize="{ minRows: 4}"
            placeholder="请输入内容"
            v-model="form.specialVersion"
            show-word-limit
            maxlength="3000"></el-input>
        </div>
      </div>
      <div class="form-absolute">
        <div class="label">
          每次事故绝对免赔额
          <!-- <span style="font-size:14px;font-weight:normal">(至少勾选和填写一项)</span> -->
        </div>
        <div class="info">
          <div class="extra"
            v-if="permissions.deductibleOneFlag">
            <div class="title">
              <el-checkbox v-model="form.deductibleOneFlag"
                class="checkbox"
                :true-label="1"
                :false-label="2"
                @change="handleCheckboxChange($event, 1)"></el-checkbox>扩展不计免赔1（0免赔）
            </div>
            <el-input type="textarea"
              :autosize="{ minRows: 4}"
              placeholder="请输入内容"
              v-model="form.deductibleOne"
              show-word-limit
              :disabled="form.deductibleOneFlag == 2"
              maxlength="1000"></el-input>
          </div>
          <div class="extra"
            v-if="permissions.deductibleTwoFlag">
            <div class="title">
              <el-checkbox v-model="form.deductibleTwoFlag"
                class="checkbox"
                :true-label="1"
                :false-label="2"
                @change="handleCheckboxChange($event, 2)"></el-checkbox>扩展不计免赔2（绝对免赔1000元）
            </div>
            <el-input type="textarea"
              :autosize="{ minRows: 4}"
              placeholder="请输入内容"
              v-model="form.deductibleTwo"
              :disabled="form.deductibleTwoFlag == 2"
              show-word-limit
              maxlength="1000"></el-input>
          </div>
          <div class="extra"
            v-if="permissions.deductibleExpandedFlag">
            <div class="title">
              <el-checkbox v-model="form.deductibleExpandedFlag"
                class="checkbox"
                :true-label="1"
                :false-label="2"
                @change="handleCheckboxChange($event, 3)"></el-checkbox>未扩展不计免赔
            </div>
            <el-input type="textarea"
              :autosize="{ minRows: 4}"
              placeholder="请输入内容"
              v-model="form.deductibleExpanded"
              :disabled="form.deductibleExpandedFlag == 2"
              show-word-limit
              maxlength="1000"></el-input>
          </div>
        </div>
      </div>
    </div>
    <span slot="footer"
      class="dialog-footer">
      <el-button @click="reset">重 置</el-button>
      <el-button type="primary"
        @click="submit">确 定</el-button>
    </span>
    <clauseDialog v-model="innerVisible"
      :info="clauseInfo" />
  </el-dialog>
</template>
<script>
import { getDictionAry, insuredAdd, insuredEdit, getUserByCharge, dictionaryBatch } from '@/api/policy.js';
import EmployeeSelect from "@/components/EmployeeSelect/index.vue";
import clauseDialog from '../../policyManagement/components/ClauseDialog.vue';
export default {
  components: { clauseDialog, EmployeeSelect },
  props: {
    showDialog: Boolean,
    info: Object,
    permissions: {
      type: Object,
      default: () => { }
    }
  },
  model: {
    prop: 'showDialog',
    event: 'change'
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (!v) {
          this.$emit('change', false);
        }
      }
    },
    showDialog: {
      handler(v) {
        if (v) {
          this.dialogVisible = true;
          if (this.info.id) {
            this.$nextTick(() => {
              this.form.categoryName = Number(this.form.categoryName) || '';
              this.form.companyName = Number(this.form.companyName) || this.insuranceCompany[0].dictKey;
              this.form.productLineName = Number(this.form.productLineName) || '';
              this.form.recorder = { userId: this.form.recorderId, userName: this.form.recorderName };
              this.form.policySafeguardPlanList.forEach((el, key) => {
                let temp = this.attachData.find(val => {
                  return val.dictValue === el.nameOfInsuranceClause;
                });
                if (temp) {
                  el.attachInfo = temp;
                  this.form.policySafeguardPlanList[key].stipulation = temp.stipulation;
                }
              });
              this.policyPlanListOld = JSON.parse(JSON.stringify(this.form.policySafeguardPlanList));
              this.$forceUpdate();
            });
          } else {
            this.form.recorder = this.chargeUserList[0];
            this.form.recorderId = this.chargeUserList[0].userId;
            this.form.recorderName = this.chargeUserList[0].userName;
            this.form.companyName = this.insuranceCompany[0].dictKey;
            this.policyPlanListOld = JSON.parse(JSON.stringify(this.form.policySafeguardPlanList));
          }
        }
      }
    },
    info: {
      handler(v) {
        if (v) {
          this.form = JSON.parse(JSON.stringify(v));
          this.oldData = JSON.parse(JSON.stringify(v));
          this.getInsured();
          this.$forceUpdate();
        }
      }
    },
    'form.policySafeguardPlanList': {
      immediate: true,
      deep: true,
      handler(n) {
        console.log('触发！');
        //计算
        let spimObj = null;
        n.forEach((item, i) => {
          spimObj = this.specialProtectionScheme.find(spim => {
            if (spim.name == this.form.schemeName && spim.itemName == item.nameOfInsuranceClause) {
              spim.index = i;
              let oObj = this.policyPlanListOld.find(oitem => {
                return spim.itemName == oitem.nameOfInsuranceClause;
              });
              if (oObj) {
                spim.type.forEach((ty, ti) => {
                  if (Number(item[ty] || 0) == Number(oObj[ty] || 0)) {
                    spim[`fun${ ti + 1 }`](item, n, i);
                  }
                });
              }
              return true;
            }
          });
        });
        this.policyPlanListOld = JSON.parse(JSON.stringify(n));
      }
    }
  },
  data() {
    return {
      dialogVisible: false,
      form: { schemeName: '', policySafeguardPlanList: [{ annualFee: '0', premium: '', insuranceAmount: '', maximumIndemnityNorm: '', safeguardType: 1, rateType: '%' }], deductibleOneFlag: 2, deductibleTwoFlag: 2, deductibleExpandedFlag: 2, readerId: '', companyName: '' },
      options: [],
      attachOptions: [],
      attachData: [],
      oldData: {},
      chargeUserList: [],
      innerVisible: false,
      clauseInfo: {},
      vehicleType: [],
      category_name: [],
      insuranceCompany: [],
      policyPlanListOld: {},
      specialProtectionScheme: [
        {
          name: '起重机方案',
          type: ['premium', 'insuranceAmount'],
          // 不进行横向计算
          calculation: true,
          itemName: '扩展不计免赔',
          index: -1,
          fun1(item, arry, i) {
            let sum = 0;
            arry.forEach((item, index) => {
              if (i != index) {
                sum += Number(item.premium || 0);
              }
            });
            item.premium = (sum * (Number(item.annualFee || 0) / 100)).toFixed(2);
          },
          fun2(item, arry, i) {
            item.insuranceAmount = arry[0].insuranceAmount;
          }
        },
        {
          name: '平安汽车吊承保方案',
          itemName: '扩展不计免赔',
          type: ['insuranceAmount'],
          calculation: true,
          index: -1,
          fun1(item, arry, i) {
            let sum = 0;
            arry.forEach((item, index) => {
              if (i != index) {
                sum += Number(item.premium || 0);
              }
            });
            item.insuranceAmount = sum.toFixed(2);
            //item.premium = (sum * (Number(item.rate || 0) / 100)).toFixed(2);
          }
        }
      ],
    };
  },
  created() {
    this.getInsured();
    this.getChargeUserList();
    this.init();
  },
  computed: {

  },
  methods: {
    init() {
      dictionaryBatch({ codes: 'vehicleType,category_name,insuranceCompany' }).then(res => {
        console.log(res);
        const {
          vehicleType,
          category_name,
          insuranceCompany
        } = res.data;
        this.vehicleType = vehicleType || [];
        this.category_name = category_name || [];
        this.insuranceCompany = insuranceCompany || [];
      });
    },
    showClause(value) {
      this.clauseInfo = value;
      this.innerVisible = true;
    },
    handleSelect(value) {
      this.form.recorderId = value.userId;
      this.form.recorderName = value.userName;
      this.$forceUpdate();
    },
    handleAttachSelect(value, item) {
      let temp = this.attachOptions.find(el => {
        return el.dictValue === value;
      });
      if (temp) {
        //   this.$set(item, 'stipulation', temp.stipulation);
        this.$set(item, 'dictValue', value);
        //   this.$set(item, 'typeOfInsuranceClause', temp.dictKey);
        item.stipulation = temp.stipulation;
        //item.dictValue = value;
        item.typeOfInsuranceClause = temp.dictKey;
        //console.log(this.form.safeguardPlanList);
        //   return temp.stipulation;
      }
      // else {
      //   return null;
      // }
      this.handleOnceSingleOption(this.attachOptions, this.form.policySafeguardPlanList, 'dictValue');
    },
    handleCheckSelect(val, name) {
      name = name.split(',');
      var newNames = name.filter((item, index) => {
        return name.indexOf(item) === index;
      });
      this.form.readerId = val;
      this.form.readerName = newNames.toString();
    },
    handleMainSelect(value, item) {
      let temp = this.options.find(el => {
        return el.dictValue === value;
      });
      item.typeOfInsuranceClause = temp.dictKey;
      item.dictValue = value;
      this.form.policySafeguardPlanList = [this.form.policySafeguardPlanList[0]];
      switch (value) {
        case '财产一切险主险':
          this.attachOptions = this.attachData.filter(val => {
            return val.dictKey > 100 && val.dictKey <= 200;
          });
          break;
        case '财产综合险主险':
          this.attachOptions = this.attachData.filter(val => {
            return val.dictKey > 200 && val.dictKey <= 300;
          });
          break;
        case '第三者责任险':
          this.attachOptions = this.attachData.filter(val => {
            return val.dictKey > 300 && val.dictKey <= 400;
          });
          break;
        case '起重机综合险':
          this.attachOptions = this.attachData.filter(val => {
            return val.dictKey > 400 && val.dictKey <= 500;
          });
          break;
        case "工程机械设备险":
          this.attachOptions = this.attachData.filter((val) => {
            return val.dictKey > 200 && val.dictKey <= 300;
          });
          break;
        case "驾意险":
          this.attachOptions = [];
          break;
        default:
          break;
      }
      console.log(this.attachOptions);
    },
    getChargeUserList() {
      getUserByCharge().then(res => {
        this.chargeUserList = res.data;
      });
    },
    amountInput(item) {
      item.maximumIndemnityNorm = item.insuranceAmount;
      if (item.annualFee && item.insuranceAmount && item.rateType === '%') {
        item.premium = (item.insuranceAmount * item.annualFee * 0.01).toFixed(2);
      }
    },
    handleInput(data) {
      if (data.annualFee && data.insuranceAmount && data.rateType === '%') {
        data.premium = (data.insuranceAmount * data.annualFee * 0.01).toFixed(2);
      }
      //this.InputcalculatePremium(data);
      //this.$forceUpdate();
    },
    InputcalculatePremium(data) {
      if (this.form.schemeName == '平安汽车吊承保方案') {
        let otherSum = 0;
        let spIndex = -1;
        this.form.policySafeguardPlanList.forEach((item, index) => {
          if (item.nameOfInsuranceClause != '扩展不计免赔') {
            otherSum += Number(item.premium);
          } else {
            spIndex = index;
          }
        });
        if (spIndex != -1 && data.nameOfInsuranceClause != '扩展不计免赔') {
          this.form.policySafeguardPlanList[spIndex].insuranceAmount = otherSum;
        }
        //this.form.safeguardPlanList[spIndex].premium = (this.form.safeguardPlanList[spIndex].insuranceAmount * this.form.safeguardPlanList[spIndex].annualFee * 0.01).toFixed(2);
      }
    },
    handleCommand(command) {
      command.data.rateType = command.value;
    },
    dialogClose() {
      this.resetForm();
    },
    resetForm() {
      this.form = { policySafeguardPlanList: [{ annualFee: '0', premium: '', insuranceAmount: '', maximumIndemnityNorm: '', safeguardType: 1, rateType: '%' }], deductibleOneFlag: 2, deductibleTwoFlag: 2, deductibleExpandedFlag: 2 };
    },
    reset() {
      if (this.info.id) {
        this.form = JSON.parse(JSON.stringify(this.oldData));
      } else {
        this.resetForm();
      }
    },
    submit() {
      if (!this.form.schemeName) {
        return this.$message({
          message: '请填写方案名称',
          type: 'warning'
        });
      }
      if (!this.form.categoryName) {
        return this.$message({
          message: '请选择所属部门',
          type: 'warning'
        });
      }
      if (!this.form.recorder) {
        return this.$message({
          message: '请选择录单员',
          type: 'warning'
        });
      }
      if (!this.form.readerId) {
        return this.$message({
          message: '请选择可查看用户',
          type: 'warning'
        });
      }
      if (this.form.policySafeguardPlanList.some(ele => {
        if (ele.premium) {
          return ele.premium.length > 20;
        } else {
          return false;
        }
      })) {
        return this.$message.error('保费长度应小于等于20');
      }
      if ((this.form.deductibleOneFlag === 2 || !this.form.deductibleOne) && (this.form.deductibleTwoFlag === 2 || !this.form.deductibleTwo) && (this.form.deductibleExpandedFlag === 2 || !this.form.deductibleExpanded)) {
        return this.$message({
          message: '请勾选和填写一项每次事故绝对免赔额',
          type: 'warning'
        });
        //   return this.$message.warning("点击提交必填项没有填写的话");
      }
      this.form.policySafeguardPlanList.forEach(element => {
        if (element.annualFee) {
          element.annualFeeRate = element.annualFee + element.rateType;
        } else {
          element.annualFeeRate = '';
        }
      });
      var port = null;
      this.form.category = this.$route.meta.policyType;
      if (this.form.id) {
        port = insuredEdit;
      } else {
        port = insuredAdd;
      }
      port(this.form).then(res => {
        if (res) {
          this.dialogVisible = false;
          this.resetForm();
          this.$message({
            message: '操作成功',
            type: 'success'
          });
        }
      });
    },
    tableAdd() {
      if (!this.attachOptions.length) {
        this.$message.error('该主险暂无附加险');
        return false;
      }
      this.form.policySafeguardPlanList.push({ annualFee: '0', premium: '', insuranceAmount: '', maximumIndemnityNorm: '', safeguardType: 2, rateType: '%', dictValue: '' });
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => { });
    },
    delTableItem(index, name, row) {
      // key:201 拓展不计免赔
      this.attachOptions.find(el => {
        if (el.dictValue === name) {
          el.disabled = false;
        }
      });
      this.form.policySafeguardPlanList.splice(index, 1);
      this.$forceUpdate();
      //this.handleOnceSingleOption(this.attachOptions, this.form.safeguardPlanList, 'dictValue');
      this.InputcalculatePremium(row);
    },
    getInsured() {
      getDictionAry({ codes: 'primaryInsuranceType,attachInsuranceType' }).then(res => {
        this.options = res.data.primaryInsuranceType;
        this.attachOptions = res.data.attachInsuranceType;
        this.attachData = res.data.attachInsuranceType.map(item => {
          return {
            ...item,
            disabled: false
          };
        });
        if (this.form.policySafeguardPlanList.length > 0) {
          switch (this.form.policySafeguardPlanList[0].nameOfInsuranceClause) {
            case '财产一切险主险':
              this.attachOptions = this.attachData.filter(val => {
                return val.dictKey > 100 && val.dictKey <= 200;
              });
              break;
            case '财产综合险主险':
              this.attachOptions = this.attachData.filter(val => {
                return val.dictKey > 200 && val.dictKey <= 300;
              });
              break;
            case '第三者责任险':
              this.attachOptions = this.attachData.filter(val => {
                return val.dictKey > 300 && val.dictKey <= 400;
              });
              break;
            case '起重机综合险':
              this.attachOptions = this.attachData.filter(val => {
                return val.dictKey > 400 && val.dictKey <= 500;
              });
              break;
            case "工程机械设备险":
              this.attachOptions = this.attachData.filter((val) => {
                return val.dictKey > 200 && val.dictKey <= 300;
              });
              break;
            case "驾意险":
              this.attachOptions = [];
              break;
          }
          this.form.policySafeguardPlanList.forEach((el) => {
            this.attachOptions.forEach(item => {
              if (el.nameOfInsuranceClause == item.dictValue) {
                item.disabled = true;
              }
            });
          });
        }
      });
    },
    handleCheckboxChange(e, type) {
      if (type == 1) {
        this.form.deductibleTwoFlag = 2;
        this.form.deductibleExpandedFlag = 2;
      } else if (type == 2) {
        this.form.deductibleOneFlag = 2;
        this.form.deductibleExpandedFlag = 2;
      } else if (type == 3) {
        this.form.deductibleOneFlag = 2;
        this.form.deductibleTwoFlag = 2;
      }
      this.$forceUpdate();
    },
    handleOnceSingleOption(optionArr, selectArr, prop) {
      let drawOutOptionArr = [];
      for (let i = 1; i < selectArr.length; i++) {
        for (let index = 0; index < optionArr.length; index++) {
          if (optionArr[index].dictValue == selectArr[i][prop]) {
            this.$set(optionArr[index], 'disabled', true);
            optionArr[index].index = index;
            drawOutOptionArr.push(optionArr[index]);
            optionArr.splice(index, 1);
            break;
          } else {
            this.$set(optionArr[index], 'disabled', false);
          }
        }
      }
      drawOutOptionArr.forEach(item => {
        optionArr.splice(item.index, 0, item);
      });
      this.$forceUpdate();
    },
    attachOptionsDdisabled(item) {
      let flag = false;
      this.form.policySafeguardPlanList.findIndex(val => val.nameOfInsuranceClause == item.dictValue) > -1 ? flag = true : '';
      return flag;
    },
    handleCompanyName(e) {
      this.form.companyNameCh = this.insuranceCompany.find(val => {
        return val.dictKey == e;
      }).dictValue;
      this.$forceUpdate();
    }
  },
}
</script>
<style lang="scss" scoped>
.policy-dialog {
  .el-dropdown-link {
    cursor: pointer;
    color: #409eff;
    display: flex;
    align-items: center;
    margin-left: 5px;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
  .basic {
    display: flex;
    margin-bottom: 15px;
    flex-wrap: wrap;
  }
  .form-item {
    width: 50%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .label {
      white-space: nowrap;
      width: 56px;
      margin-right: 5px;
      position: relative;
    }
    .required::before {
      position: absolute;
      left: -10px;
      top: -5px;
      content: '*';
      color: red;
      font-weight: bold;
      font-size: 18px;
    }
    .info {
      flex: 1;
      .limit {
        margin-left: 15px;
      }
    }
    .btn {
      position: absolute;
      right: 25px;
    }
  }
  .line {
    margin-bottom: 15px;
    width: 100%;
    border-top: 1px dashed #e7e7e7;
  }
  .form-plan {
    .info {
      margin-top: 10px;
      .btn {
        margin-top: 10px;
      }
    }
  }
  .form-textarea,
  .form-absolute {
    margin-top: 20px;
    .label {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    .info {
      .extra {
        margin-top: 10px;
        .title {
          margin-bottom: 5px;
          .checkbox {
            margin-right: 5px;
          }
        }
      }
    }
  }
}
::v-deep .form-item .info .el-input {
  width: 350px;
}
::v-deep .form-item .info .el-select {
  width: 350px;
}
::v-deep .form-item .info .el-select .el-input {
  width: 100%;
}
::v-deep .policy-dialog {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  .el-dialog__header {
    font-size: 18px;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    padding: 0 24px;
    background: #4278c9;
    .el-dialog__title {
      color: #fff;
    }
    .el-dialog__headerbtn {
      top: 11px;
      .el-dialog__close {
        color: #fff;
        font-weight: bold;
      }
    }
  }
  .el-dialog__body {
    height: 600px;
    overflow: auto;
    padding: 24px;
  }
}
</style>
