<template>
  <!-- 承保 -->
  <div>
    <div class="type-select">
      <div
        class="item"
        :class="{ active: curType === index }"
        v-for="(item, index) in titleArr"
        :key="item.text"
        @click="handleSelect(item.text, index)"
      >
        {{ item.text }}({{ item.value }})
      </div>
    </div>
    <GeneralTable
      ref="GeneralTable"
      :column="column"
      :option="option"
      :dataList="list"
      :totalNum="totalNum"
      @search-change="handleSearch"
      @del-change="handleDel"
      @add-change="handleEdit({}, '添加')"
      @export-data="handleExport"
      tableName="InsuredManagement"
    >
      <template slot="operate" slot-scope="{ item }">
        <el-button
          type="text"
          v-if="handleHasPerms(`M23-edit_${$route.meta.policyType}`)"
          @click="handleEdit(item, '编辑')"
          >编辑</el-button
        >
        <el-button
          type="text"
          v-if="handleHasPerms(`M23-del_${$route.meta.policyType}`)"
          @click="handleDel([item])"
          >删除</el-button
        >
      </template>
    </GeneralTable>
    <edit-dialog
      @change="handleVisible"
      v-model="showDialog"
      :permissions="InsuredPermissions"
      :info="curInfo"
    />
  </div>
</template>
<script>
import GeneralTable from "@/components/GeneralTable/index.vue";
import { hasPerms, dataPermissions } from "@/utils/auth";
import {
  insuredList,
  exportInsured,
  insuredListDel,
  getCreateUser,
  getInsuredDetail,
  getSchemeTotal,
} from "@/api/policy.js";
import editDialog from "./components/editDialog.vue";
export default {
  components: {
    GeneralTable,
    editDialog,
  },
  data() {
    return {
      totalNum: 0,
      list: [],
      option: {
        isAdd: hasPerms(`M23-add_${this.$route.meta.policyType}`), //添加 事件 @add-change
        isDel: hasPerms(`M23-del_${this.$route.meta.policyType}`), //删除 @del-change
        isEdit: hasPerms(`M23-edit_${this.$route.meta.policyType}`), // 编辑 @edit-change
        isExport: hasPerms(`M23-export_${this.$route.meta.policyType}`),
        isSearch: true, // 搜索 @search-change
        isRefresh: true, // 刷新 @search-change
        pageSize: 10,
        searchList: [
          {
            label: "方案名称",
            prop: "schemeName",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入方案名称",
          },
          {
            label: "适用范围",
            prop: "scopeOfApplication",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入适用范围",
          },
          {
            label: "所属部门",
            prop: "categoryName",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入所属部门",
          },
          {
            label: "设备类型",
            prop: "productLineName",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入设备类型",
          },
          {
            label: "保险公司",
            prop: "companyNameCh",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入保险公司",
          },
          {
            label: "分支机构",
            prop: "branches",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入分支机构",
          },
          {
            label: "录单员",
            prop: "recorderName",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入录单员",
          },
          {
            label: "创建时间",
            formType: "daterange",
            clearable: true,
            format: "yyyy.MM.dd",
            prop: ["createTimeBegin", "createTimeEnd"],
          },
        ],
      },
      column: [
        {
          label: "序号",
          type: "index",
          isShow: true,
          fixed: "left",
        },
        {
          label: "方案名称",
          prop: "schemeName",
          isShow: true,
          isPermissions: dataPermissions(
            `M23_${this.$route.meta.policyType}-View`,
            "schemeName"
          ),
        },
        {
          label: "所属部门",
          prop: "categoryName",
          isShow: true,
          isPermissions: dataPermissions(
            `M23_${this.$route.meta.policyType}-View`,
            "categoryName"
          ),
        },
        {
          label: "设备类型",
          prop: "productLineName",
          isShow: true,
          isPermissions: dataPermissions(
            `M23_${this.$route.meta.policyType}-View`,
            "productLineName"
          ),
        },
        {
          label: "保险公司",
          prop: "companyNameCh",
          isShow: true,
          isPermissions: dataPermissions(
            `M23_${this.$route.meta.policyType}-View`,
            "companyNameCh"
          ),
        },
        {
          label: "分支机构",
          prop: "branches",
          isShow: true,
          isPermissions: dataPermissions(
            `M23_${this.$route.meta.policyType}-View`,
            "branches"
          ),
        },
        {
          label: "总保费",
          prop: "sumPremium",
          isShow: false,
          isPermissions: dataPermissions(
            `M23_${this.$route.meta.policyType}-View`,
            "sumPremium"
          ),
        },
        {
          label: "适用范围",
          prop: "scopeOfApplication",
          isShow: true,
          isPermissions: dataPermissions(
            `M23_${this.$route.meta.policyType}-View`,
            "scopeOfApplication"
          ),
        },
        {
          label: "录单员",
          prop: "recorderName",
          isShow: true,
          isPermissions: dataPermissions(
            `M23_${this.$route.meta.policyType}-View`,
            "recorderName"
          ),
        },
        {
          label: "创建时间",
          prop: "createTime",
          width: 200,
          isShow: true,
          isPermissions: dataPermissions(
            `M23_${this.$route.meta.policyType}-View`,
            "createTime"
          ),
        },
        {
          label: "更新时间",
          prop: "updateTime",
          width: 200,
          isShow: true,
          isPermissions: dataPermissions(
            `M23_${this.$route.meta.policyType}-View`,
            "updateTime"
          ),
        },
        {
          label: "操作",
          prop: "operate",
          isShow: true,
          width: 220,
          fixed: "right",
          isSlot: true,
        },
      ],
      creatorList: [],
      showDialog: false,
      curInfo: {},
      detailPermissions: {
        // 录单员
        recorder: dataPermissions(
          `M23_${this.$route.meta.policyType}-Edit`,
          "recorder"
        ),
        // 扩展不计免赔2（绝对免赔1000元）
        deductibleTwo: dataPermissions(
          `M23_${this.$route.meta.policyType}-Edit`,
          "deductibleTwo"
        ),
        // 扩展不计免赔1（0免赔）
        deductibleOne: dataPermissions(
          `M23_${this.$route.meta.policyType}-Edit`,
          "deductibleOne"
        ),
        // 查看用户
        reader: dataPermissions(
          `M23_${this.$route.meta.policyType}-Edit`,
          "reader"
        ),
        // 方案名称
        schemeName: dataPermissions(
          `M23_${this.$route.meta.policyType}-Edit`,
          "schemeName"
        ),
        // 排序
        sort: dataPermissions(
          `M23_${this.$route.meta.policyType}-Edit`,
          "sort"
        ),
        // 每次事故绝对免陪额
        absoluteExemptionPerAccident: dataPermissions(
          `M23_${this.$route.meta.policyType}-Edit`,
          "absoluteExemptionPerAccident"
        ),
        // 是否勾选扩展不计免赔1（0免赔）
        deductibleOneFlag: dataPermissions(
          `M23_${this.$route.meta.policyType}-Edit`,
          "deductibleOneFlag"
        ),
        // 是否勾选未扩展不计免赔
        deductibleExpandedFlag: dataPermissions(
          `M23_${this.$route.meta.policyType}-Edit`,
          "deductibleExpandedFlag"
        ),
        // 适用范围
        scopeOfApplication: dataPermissions(
          `M23_${this.$route.meta.policyType}-Edit`,
          "scopeOfApplication"
        ),
        // 免费扩展条款
        freeExtensionTerms: dataPermissions(
          `M23_${this.$route.meta.policyType}-Edit`,
          "freeExtensionTerms"
        ),
        // 特别约定
        specialAgreement: dataPermissions(
          `M23_${this.$route.meta.policyType}-Edit`,
          "specialAgreement"
        ),
        // 是否勾选扩展不计免赔2（绝对免赔1000元）
        deductibleTwoFlag: dataPermissions(
          `M23_${this.$route.meta.policyType}-Edit`,
          "deductibleTwoFlag"
        ),
        // 未扩展不计免赔
        deductibleExpanded: dataPermissions(
          `M23_${this.$route.meta.policyType}-Edit`,
          "deductibleExpanded"
        ),
        specialVersion: dataPermissions(
          `M23_${this.$route.meta.policyType}-Edit`,
          "specialVersion"
        ),
        categoryName: dataPermissions(
          `M23_${this.$route.meta.policyType}-Edit`,
          "categoryName"
        ),
        productLineName: dataPermissions(
          `M23_${this.$route.meta.policyType}-Edit`,
          "productLineName"
        ),
        companyName: dataPermissions(
          `M23_${this.$route.meta.policyType}-Edit`,
          "companyName"
        ),
        branches: dataPermissions(
          `M23_${this.$route.meta.policyType}-Add`,
          "branches"
        ),
      },
      addPermissions: {
        // 录单员
        recorder: dataPermissions(
          `M23_${this.$route.meta.policyType}-Add`,
          "recorder"
        ),
        // 扩展不计免赔2（绝对免赔1000元）
        deductibleTwo: dataPermissions(
          `M23_${this.$route.meta.policyType}-Add`,
          "deductibleTwo"
        ),
        // 扩展不计免赔1（0免赔）
        deductibleOne: dataPermissions(
          `M23_${this.$route.meta.policyType}-Add`,
          "deductibleOne"
        ),
        // 查看用户
        reader: dataPermissions(
          `M23_${this.$route.meta.policyType}-Add`,
          "reader"
        ),
        // 方案名称
        schemeName: dataPermissions(
          `M23_${this.$route.meta.policyType}-Add`,
          "schemeName"
        ),
        // 排序
        sort: dataPermissions(`M23_${this.$route.meta.policyType}-Add`, "sort"),
        // 每次事故绝对免陪额
        absoluteExemptionPerAccident: dataPermissions(
          `M23_${this.$route.meta.policyType}-Add`,
          "absoluteExemptionPerAccident"
        ),
        // 是否勾选扩展不计免赔1（0免赔）
        deductibleOneFlag: dataPermissions(
          `M23_${this.$route.meta.policyType}-Add`,
          "deductibleOneFlag"
        ),
        // 是否勾选未扩展不计免赔
        deductibleExpandedFlag: dataPermissions(
          `M23_${this.$route.meta.policyType}-Add`,
          "deductibleExpandedFlag"
        ),
        // 适用范围
        scopeOfApplication: dataPermissions(
          `M23_${this.$route.meta.policyType}-Add`,
          "scopeOfApplication"
        ),
        // 免费扩展条款
        freeExtensionTerms: dataPermissions(
          `M23_${this.$route.meta.policyType}-Add`,
          "freeExtensionTerms"
        ),
        // 特别约定
        specialAgreement: dataPermissions(
          `M23_${this.$route.meta.policyType}-Add`,
          "specialAgreement"
        ),
        // 是否勾选扩展不计免赔2（绝对免赔1000元）
        deductibleTwoFlag: dataPermissions(
          `M23_${this.$route.meta.policyType}-Add`,
          "deductibleTwoFlag"
        ),
        // 未扩展不计免赔
        deductibleExpanded: dataPermissions(
          `M23_${this.$route.meta.policyType}-Add`,
          "deductibleExpanded"
        ),
        specialVersion: dataPermissions(
          `M23_${this.$route.meta.policyType}-Add`,
          "specialVersion"
        ),
        categoryName: dataPermissions(
          `M23_${this.$route.meta.policyType}-Add`,
          "categoryName"
        ),
        productLineName: dataPermissions(
          `M23_${this.$route.meta.policyType}-Add`,
          "productLineName"
        ),
        companyName: dataPermissions(
          `M23_${this.$route.meta.policyType}-Add`,
          "companyName"
        ),
        branches: dataPermissions(
          `M23_${this.$route.meta.policyType}-Add`,
          "branches"
        ),
      },
      InsuredPermissions: {},
      titleArr: [],
      curType: 0,
    };
  },
  created() {
    this.getCreator();
    this.getTotal();
  },
  computed: {},
  methods: {
    handleSelect(value, index) {
      this.curType = index;
      // 筛选条件更改时需变化
      this.$refs.GeneralTable.searchData.search2 =
        value === "全部" ? "" : value;
      this.$refs.GeneralTable.getList();
      this.getTotal();
    },
    getTotal() {
      getSchemeTotal().then((res) => {
        let arr = [];
        let totalNum = 0;
        for (let key in res.data) {
          if (key == "全部") {
            totalNum = res.data[key];
          } else {
            arr.push({ text: key, value: res.data[key] });
          }
        }
        arr.unshift({ text: "全部", value: totalNum });
        this.titleArr = arr;
      });
    },
    handleVisible(item) {
      this.changeList();
      this.curInfo = {
        policySafeguardPlanList: [{ safeguardType: 1, rateType: "%" }],
      };
      this.getTotal();
    },
    getCreator() {
      getCreateUser().then((res) => {
        res.data.forEach((element) => {
          element.value = element.id;
          element.text = element.account;
        });
        this.creatorList = res.data;
        this.option.searchList.find((item) => {
          return item.label === "录单员";
        }).selectList = this.creatorList;
      });
    },
    handleSearch(data) {
      data.current = data.pageNum;
      data.size = data.pageSize;
      data.vague = data.keyword;
      data.category = this.$route.meta.policyType;
      this.getList(data);
    },
    getList(data) {
      insuredList(data).then((res) => {
        this.list = res.data.records;
        this.totalNum = res.data.total;
      });
    },
    handleExport(data, obj) {
      let ids = [];
      data.forEach((element) => {
        ids.push(element.id);
      });
      obj.ids = ids;
      exportInsured(obj).then((res) => {
        if (res) {
          this.$message({
            message: "导出成功",
            type: "success",
          });
        }
      });
    },
    handleEdit(item) {
      if (item.id) {
        getInsuredDetail({ id: item.id }).then((res) => {
          if (res) {
            //TODO:safeguardPlanList ，跟换为policySafeguardPlanList
            //res.data[0].safeguardPlanList = res.data[0].policySafeguardPlanList;
            res.data[0].policySafeguardPlanList.forEach((element) => {
              if (element.annualFeeRate) {
                let temp = element.annualFeeRate.split("");
                element.rateType = temp.pop();
                element.annualFee = temp.join("");
              } else {
                element.rateType = "%";
                element.annualFee = "";
              }
            });
            this.curInfo = res.data[0];
            this.showDialog = true;
          }
        });
        this.InsuredPermissions = this.detailPermissions;
      } else {
        this.InsuredPermissions = this.addPermissions;
        this.showDialog = true;
      }
    },
    changeList() {
      this.$refs.GeneralTable.getList();
    },
    handleDel(data) {
      if (data && data.length) {
        this.$confirm("确认删除方案?", "删除", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let temp = {
              ids: [],
              yiLiUserToken: this.$store.state.appToken,
            };
            data.forEach((element) => {
              temp.ids.push(element.id);
            });
            temp.ids = temp.ids.join(",");
            insuredListDel(temp).then((res) => {
              if (res) {
                this.$message({
                  message: "操作成功",
                  type: "success",
                });
                this.changeList();
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      } else {
        this.$message.error("请选择需要删除的方案！");
      }
    },
    handleHasPerms(e) {
      return hasPerms(e);
    },
  },
};
</script>
<style lang="scss" scoped>
.type-select {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  margin-bottom: -10px;
  border-radius: 10px 10px 0 0;
  background-color: #fff;
  .item {
    position: relative;
    padding: 13px 20px;
    color: #606266;
    margin-right: 20px;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      color: #409eff;
    }
  }
  .active {
    color: #4278c9;
    font-weight: bold;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 4px;
      border-radius: 4px;
      background-color: #4278c9;
    }
  }
}
</style>
